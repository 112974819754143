import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Intro from './components/Intro';
import About from './components/About';
import Contact from './components/Contact';
import { getSingleContentByType } from './contentService';

function App() {
  const [footer, setFooter] = useState({text: ''});

  useEffect(() => {
    getSingleContentByType('footerCopyright').then((res: any) => setFooter(res));
  }, []);

  return (
    <div className='layout-containerx'>
      <Header />
      <Intro />
      <About />
      <Contact />
      <div className='copyright-footer'>{footer?.text}</div>
    </div>
  );
}

export default App;
